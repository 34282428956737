
<template>
  <div>
    <div class="text-center my-4">
      <h5>Información del Crédito</h5>
    </div>

    <div class="container-fluid">

      <div class="row">
        <div class="col-sm-3 form-group">
          <label for="nombre" class="form-label">No. de Crédito</label>
          <input v-model="data.no_credito" type="text" name="no_credito" id="no_credito" class="form-control">
        </div>
        <div class="col-sm-3 form-group">
          <label for="nombre" class="form-label">No. Fideicomiso/Hipoteca/Mediación</label>
          <input v-model="data.no_fideicomiso" type="text" name="no_fideicomiso" id="no_fideicomiso" class="form-control">
        </div>
        <div class="col-sm-3 form-group">
          <label for="nombre" class="form-label">Nombre del acreditado</label>
          <input v-model="data.nombre" type="text" name="nombre" id="nombre" class="form-control">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 form-group">
          <label for="nombre" class="form-label">Valor Actualizado de la Garantía</label>
          <input v-model="data.valor_garantia" type="number" name="valor_garantia" id="valor_garantia" class="form-control">
        </div>
        <div class="col-sm-3 form-group">
          <label for="nombre" class="form-label">Aforo Capital Original</label>
          <input v-model="data.aforo_capital_original" type="number" name="aforo_capital_original" id="aforo_capital_original" class="form-control">
        </div>
        <div class="col-sm-3 form-group">
          <label for="nombre" class="form-label">Aforo Saldo Contable</label>
          <input v-model="data.aforo_saldo_contable" type="number" name="aforo_saldo_contable" id="aforo_saldo_contable" class="form-control">
        </div>
        <div class="col-sm-3 form-group">
          <label for="nombre" class="form-label">Saldo total o insoluto</label>
          <input v-model="data.aforo_saldo_total" type="number" name="aforo_saldo_total" id="aforo_saldo_total" class="form-control">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 form-group">
          <label for="nombre" class="form-label">Estatus Crédito</label>
          <select class="form-control" name="estatus_credito" id="estatus_credito"  v-model="data.estatus_credito" >
            <option :value="estatus" v-for="estatus in lista_estatus">{{estatus}}</option>
          </select>
        </div>
        <div class="col-sm-3 form-group">
          <label for="nombre" class="form-label">Porcentaje de beneficio de garantía BPB</label>
          <input v-model="data.porcentaje_bpb" type="number" name="porcentaje_bpb" id="porcentaje_bpb" class="form-control">
        </div>
        <div class="col-sm-4 form-group">
          <label for="nombre" class="form-label">Porcentaje de beneficio de garantía Acreedor</label>
          <input v-model="data.porcentaje_acreedor" type="number" name="porcentaje_acreedor" id="porcentaje_acreedor" class="form-control">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 form-group"  v-for="(item, index) in data.fideicomitentes">
          <label for="nombre" class="form-label">Nombre {{index+1}}° Fideicomitente/Garante</label>
          <input v-model="item.nombre" type="text" name="aforo_capital_original" id="aforo_capital_original" class="form-control">
        </div>
        <div class="col-sm-3 form-group"  v-for="(item, index) in data.fideicomisarios">
          <label for="nombre" class="form-label">Nombre {{index+1}}° Fideicomisario/Beneficiario</label>
          <input v-model="item.nombre" type="text" name="aforo_capital_original" id="aforo_capital_original" class="form-control">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 form-group">
          <label for="correo" class="form-label">Correo electrónico</label>
          <input v-model="data.correo" type="email" name="correo" id="correo" class="form-control">
        </div>
        <div class="col-sm-3 form-group">
          <label for="persona_juridica" class="form-label">Persona Jurídica</label>
          <input v-model="data.persona_juridica" type="text" name="persona_juridica" id="persona_juridica" class="form-control">
        </div>
        <div class="col-sm-3 form-group">
          <label for="persona_juridica" class="form-label">Fondeador</label>
          <select class="form-control" name="fondeador" id="fondeador"  v-model="data.fondeador" >
            <option :value="fondeador" v-for="fondeador in lista_fondeadores">{{fondeador}}</option>
          </select>
        </div>
      </div>


    </div>
  </div>


</template>

<script>
export default {
  name: "Credito"
  ,props:{id:null}
  ,components: {}
  ,data(){
    return {
      data:{
        no_credito: null
        ,no_fideicomiso:null
        ,nombre:null
        ,capital_original:null
        ,saldo_contable:null
        ,saldo_total:null
        ,valor_garantia:null
        ,aforo_capital_original:null
        ,aforo_saldo_contable:null
        ,aforo_saldo_total:null
        ,fideicomitentes: [
          {id:null, nombre: null}
          ,{id:null, nombre: null}
        ]
        ,fideicomisarios: [
          {id:null, nombre: null}
          ,{id:null, nombre: null}
        ]
        ,estatus_credito: null
        ,porcentaje_bpb:null
        ,porcentaje_acreedor:null
        ,correo:null
        ,persona_juridica:null
        ,fondeador:null
      }
      ,lista_estatus:['Activo','Inactivo']
      ,lista_fondeadores:[
        'fondeador_uno'
        ,'fondeador_dos'
      ]
    }
  }
  ,mounted() {
  }
  ,computed:{}
  ,methods:{
    guardar(){}
    ,editar(){}
  }
}
</script>

<style scoped lang="scss">

</style>
