<template>
  <Modal :options="{width: '90vw', close: true}" @close="cerrar">
    <div class="title h2 text-white">Fideicomiso</div>

    <div class="body">

      <ul class="nav nav-tabs justify-content-center" >
        <li class="nav-item">
          <a class="nav-link " :class="(tab_activa === 'tab-credito') && 'active'"  @click="tab_activa = 'tab-credito'" aria-current="page" href="#">Crédito</a>
        </li>
        <li class="nav-item">
          <a class="nav-link " :class="(tab_activa === 'tab-garantia') && 'active'" @click="tab_activa = 'tab-garantia'" aria-current="page" href="#">Garantía</a>
        </li>
        <li class="nav-item">
          <a class="nav-link " :class="(tab_activa === 'tab-notaria') && 'active'" @click="tab_activa = 'tab-notaria'" aria-current="page" href="#">Notaría</a>
        </li>
        <li class="nav-item">
          <a class="nav-link " :class="(tab_activa === 'tab-movimientos') && 'active'" @click="tab_activa = 'tab-movimientos'" aria-current="page" href="#">Movimientos</a>
        </li>
        <li class="nav-item">
          <a class="nav-link " :class="(tab_activa === 'tab-honorarios-fiduciarios') && 'active'" @click="tab_activa = 'tab-honorarios-fiduciarios'" aria-current="page" href="#">Honorarios Fiduciarios</a>
        </li>
        <li class="nav-item">
          <a class="nav-link " :class="(tab_activa === 'tab-historico') && 'active'" @click="tab_activa = 'tab-historico'" aria-current="page" href="#">Histórico</a>
        </li>
      </ul>

      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade" :class="(tab_activa === 'tab-credito') && 'show active'">
          <Credito></Credito>
        </div>
      </div>

      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade" :class="(tab_activa === 'tab-garantia') && 'show active'">
          <Garantia></Garantia>
        </div>
      </div>

      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade" :class="(tab_activa === 'tab-notaria') && 'show active'">
          <Notaria></Notaria>
        </div>
      </div>

      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade" :class="(tab_activa === 'tab-movimientos') && 'show active'">
          <Movimientos></Movimientos>
        </div>
      </div>

      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade" :class="(tab_activa === 'tab-honorarios-fiduciarios') && 'show active'">
          <HonorariosFiduciarios></HonorariosFiduciarios>
        </div>
      </div>

      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade" :class="(tab_activa === 'tab-historico') && 'show active'">
          <Historico></Historico>
        </div>
      </div>


    </div>
    <div class="footer mb-4">
      <div class="row justify-content-end">
        <div class="col-auto"><button class="btn btn-primary"><i class="fa fa-download mr-sm-1"></i>Estado de cuenta</button></div>
        <div class="col-auto"><button class="btn btn-danger" @click="cerrar"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        <div class="col-auto" v-if="!fideicomiso_id"><button type="button" class="btn btn-success" @click="guardar" id="btn-crear" name="btn-crear"><i class='fa fa-save mr-sm-1'></i>Guardar</button></div>
        <div class="col-auto" v-else> <button type="button" class="btn btn-success" id="btn-editar" name="btn-editar" @click="editar"><i class='fa fa-save mr-sm-1'></i>Guardar cambios</button></div>
      </div>
    </div>


  </Modal>
</template>

<script>

import Credito from "@/apps/garantias_app/pages/fideicomisos/Credito.vue";
import Garantia from "@/apps/garantias_app/pages/fideicomisos/Garantia.vue";
import Notaria from "@/apps/garantias_app/pages/fideicomisos/Notaria.vue";
import Movimientos from "@/apps/garantias_app/pages/fideicomisos/Movimientos.vue";
import HonorariosFiduciarios from "@/apps/garantias_app/pages/fideicomisos/HonorariosFiduciarios.vue";
import Historico from "@/apps/garantias_app/pages/fideicomisos/Historico.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: "FideicomisoForm"
  ,components: {Modal,Credito, Garantia, Notaria, Movimientos, HonorariosFiduciarios, Historico}
  ,props:{
    fideicomiso_id:null
  }
  ,data(){
    return {
      tab_activa: 'tab-credito'

    }
  }
  ,methods:{
    cerrar(){
      this.$emit('close');
    }
    ,guardar(){}
    ,editar(){}
  }
}

</script>

<style scoped lang="scss">

</style>