<template>


  <div>
    <div class="text-center my-4">
      <h5>Histórico de fideicomiso</h5>
    </div>

    <div class="container-fluid">

      <div class="observaciones" id="observaciones">
        <template v-if="historico.length > 0">
          <div class="card text-dark bg-transparent mb-3" v-for="item in historico_mostrar">
            <div class="card-header bg-white">
              {{ item.descripcion }}
            </div>
            <div class="card-footer p-sm-2">
              <small class="text-muted">{{ item.index+1 }} - {{ item.nombre }} - {{ $moment(item.created_at).format('YYYY-MM-DD HH:mm:ss') }}</small>
            </div>
          </div>

        </template>
        <div v-else class="observacion">
          <div class="descripcion">No hay histórico para mostrar</div>
          <div class="usuario">N/A - N/A - N/A</div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item"><a class="page-link" href="#" @click.prevent="pagina_anterior">Anterior</a></li>
              <template v-for="num_pagina in paginas_mostrar">
                <li :class="'page-item'+(num_pagina == pagina_actual ? ' pagina_actual' : '')"><a class="page-link" href="#" @click.prevent="pagina_actual = num_pagina">{{ num_pagina }}</a></li>
              </template>
              <li class="page-item"><a class="page-link" href="#" @click.prevent="pagina_siguiente">Siguiente</a></li>
            </ul>
          </nav>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: "Historico"
  ,components: {}
  ,data(){
    return {
      historico:[]
      ,pagina_actual: 1
      ,por_pagina: 10
      ,paginas_a_mostrar: 3
    }
  }
  ,mounted() {
    for (let i = 0; i < 25; i++) {
      this.historico.push({
        id: i
        ,descripcion: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab amet animi beatae cum molestias, possimus quidem ratione similique."
        ,nombre: "Mollitia Veniam"
        ,created_at: "2023-10-18 18:00:25"
      })
    }
  }
  ,computed:{
    total_paginas() {
      return Math.ceil(this.historico.length / this.por_pagina);
    },
    historico_mostrar(){
      let historico = [];
      let inicio = (this.pagina_actual === 1 ? 0 : ((this.pagina_actual-1) * this.por_pagina));
      let final = (this.pagina_actual < this.total_paginas ? inicio+this.por_pagina : this.historico.length)-1;
      for(let i=inicio; i<=final; i++) {
        this.historico[i].index = i;
        historico.push(this.historico[i]);
      }

      return historico;
    }
    ,paginas_mostrar() {
      let inicio = this.pagina_actual  == 1 ? 1 : (this.pagina_actual > this.paginas_a_mostrar ? (this.pagina_actual-this.paginas_a_mostrar) : 1);
      let final = this.pagina_actual == this.total_paginas ? this.total_paginas : (this.pagina_actual < (this.total_paginas - this.paginas_a_mostrar) ? (this.pagina_actual + this.paginas_a_mostrar) : this.total_paginas);

      let paginas = [];

      for(let i=inicio; i<=final; i++) {
        paginas.push(i);
      }

      return paginas;
    }
  }
  ,methods:{
    pagina_anterior() {
      if (this.pagina_actual === 1)
        return;
      this.pagina_actual -= 1;
    }
    ,pagina_siguiente() {
      if(this.pagina_actual === this.total_paginas)
        return;
      this.pagina_actual += 1;
    }
  }
}
</script>

<style scoped lang="scss">

</style>
