
<template>
  <div>
    <div class="text-center my-4">
      <h5>Información de Movimientos</h5>
    </div>

    <div class="container-fluid">

      <div class="row justify-content-center">
        <div class="col-sm-3 form-group">
          <label for="tipo_baja_cartera" class="form-label">Tipo de baja de cartera</label>
          <select class="form-control" name="tipo_baja_cartera" id="tipo_baja_cartera"  v-model="data.tipo_baja_cartera" >
            <option :value="tipo" v-for="tipo in tipos_baja_cartera">{{tipo}}</option>
          </select>
        </div>
        <div class="col-sm-3 form-group">
          <label for="estatus_revision" class="form-label">Estatus de la reversión (Liberación)</label>
          <select class="form-control" name="estatus_revision" id="estatus_revision"  v-model="data.estatus_revision" >
            <option :value="estatus" v-for="estatus in lista_estatus_revision">{{estatus}}</option>
          </select>
        </div>
        <div class="col-sm-3 form-group">
          <label for="tipo_liberacion" class="form-label">Tipo de liberación</label>
          <select class="form-control" name="tipo_liberacion" id="tipo_liberacion"  v-model="data.tipo_liberacion" >
            <option :value="tipo" v-for="tipo in tipos_liberacion">{{tipo}}</option>
          </select>
        </div>
        <div class="col-sm-2 form-group">
          <label for="fecha_liberacion" class="form-label">Fecha de liberación</label>
          <input v-model="data.fecha_liberacion" type="date" name="fecha_liberacion" id="fecha_liberacion" class="form-control">
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-sm-3 form-group">
          <label for="monto_cesion_derechos" class="form-label">Monto de la cesión de derechos</label>
          <input v-model="data.monto_cesion_derechos" type="number" name="monto_cesion_derechos" id="monto_cesion_derechos" class="form-control">
        </div>
        <div class="col-sm-3 form-group">
          <label for="monto_venta" class="form-label">Monto de venta final</label>
          <input v-model="data.monto_venta" type="text" name="monto_venta" id="monto_venta" class="form-control">
        </div>
        <div class="col-sm-3 form-group">
          <label for="estatus_judicial" class="form-label">Estatus judicial</label>
          <input v-model="data.estatus_judicial" type="text" name="estatus_judicial" id="estatus_judicial" class="form-control">
        </div>
        <div class="col-sm-2 form-group">
          <label for="fecha_cesion" class="form-label">Fecha de cesión</label>
          <input v-model="data.fecha_cesion" type="date" name="fecha_cesion" id="fecha_cesion" class="form-control">
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-sm-3 form-group">
          <label for="inversionista" class="form-label">Inversionista</label>
          <input v-model="data.inversionista" type="text" name="inversionista" id="inversionista" class="form-control">
        </div>
        <div class="col-sm-3 form-group">
          <label for="broker_venta" class="form-label">Broker Venta de Activos</label>
          <input v-model="data.broker_venta" type="text" name="broker_venta" id="broker_venta" class="form-control">
        </div>
        <div class="col-sm-2 form-group">
          <label for="no_expediente" class="form-label">No. de Expediente</label>
          <input v-model="data.no_expediente" type="text" name="no_expediente" id="no_expediente" class="form-control">
        </div>
        <div class="col-sm-3 form-group">
          <label for="juzgado" class="form-label">Juzgado</label>
          <input v-model="data.juzgado" type="text" name="juzgado" id="juzgado" class="form-control">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Movimientos"
  ,components: {}
  ,data(){
    return {
      data:{
        tipo_baja_cartera: null
        ,estatus_revision: null
        ,tipo_liberacion: null
        ,fecha_liberacion: null
        ,monto_cesion_derechos: null
        ,monto_venta: null
        ,estatus_judicial: null
        ,fecha_cesion: null
        ,inversionista: null
        ,broker_venta: null
        ,no_expediente: null
        ,juzgado: null
      }
      ,tipos_baja_cartera: ['Defunción', 'Liquidación', 'Venta de derechos', 'Ejecución', 'Entrega de posesión']
      ,lista_estatus_revision: ['Reversado','No reversado']
      ,tipos_liberacion: ['Reversión','Transmisión en ejecución','Sustitución fiduciaria']

    }
  }
  ,mounted() {
  }
  ,computed:{}
  ,methods:{}
}
</script>

<style scoped lang="scss">

</style>
