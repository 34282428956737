
<template>
  <div>
    <div class="text-center my-4">
      <h5>Información de la Notaría</h5>
    </div>

    <div class="container-fluid">

      <div class="row justify-content-center">
        <div class="col-sm-4 form-group">
          <label for="fecha_constitucion" class="form-label">Fecha de constitución del fideicomiso</label>
          <input v-model="data.fecha_constitucion" type="text" name="fecha_constitucion" id="fecha_constitucion" class="form-control">
        </div>
        <div class="col-sm-4 form-group">
          <label for="no_instrumento_notarial" class="form-label">No. de instrumento notarial</label>
          <input v-model="data.no_instrumento_notarial" type="text" name="no_instrumento_notarial" id="no_instrumento_notarial" class="form-control">
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-sm-4 form-group">
          <label for="no_notaria" class="form-label">No. de Notaría y Entidad de la notaría</label>
          <input v-model="data.no_notaria" type="text" name="no_notaria" id="no_notaria" class="form-control">
        </div>
        <div class="col-sm-4 form-group">
          <label for="nombre_notario" class="form-label">Nombre de notario</label>
          <input v-model="data.nombre_notario" type="text" name="nombre_notario" id="nombre_notario" class="form-control">
        </div>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "Notaria"
  ,components: {}
  ,data(){
    return {
      data:{
        fecha_constitucion:null
        ,no_instrumento_notarial:null
        ,no_notaria:null
        ,nombre_notario:null
      }
    }
  }
  ,mounted() {
  }
  ,computed:{}
  ,methods:{}
}
</script>

<style scoped lang="scss">

</style>
